import React, { useState, useEffect } from "react"
import { Link } from "gatsby";
const Banner = ({onClose}) => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowBanner(true);
    }, 5000);
  }, []);

  const handleClose = () => {
    setShowBanner(false);
  };
  return (
    <>
    {showBanner && (
        <header className="app-notification justify-content-center"> UPCOMING WEBINAR:&nbsp;Your LinkedIn Outreach Sucks (& What You Can Do To Fix It).&nbsp;<button className="btn btn-link"><a href="https://us06web.zoom.us/webinar/register/8717232100978/WN_kVOKHEUURsi9lMhyFo8mTA" target="_blank" className="link" >Register Now</a></button><button type="button" className="btn-close" aria-label="Close" onClick={handleClose}></button></header>
    )}
    </>
  )
}

export default Banner
